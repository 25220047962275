<template>
  <div>
    <!--    Cabecera con parte del vídeo-->
    <section class="py-5 bg-trama bg-primary">
      <div class="container">
        <div class="row">
          <div class="col-sm-10 text-white my-5"><h1>Esto (también) es Madrid</h1></div>
        </div>
      </div>
      <div class="container-fluid mb-5">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-10">
            <div style="padding-top: 56.25%; position: relative; overflow: hidden;">
              <iframe
                allowfullscreen=""
                scrolling="no"
                allow="autoplay;fullscreen"
                style="position: absolute; height: 100%; width: 100%; left: 0px; top: 0px;"
                src="https://onelineplayer.com/player.html?autoplay=true&autopause=false&muted=true&loop=true&url=https%3A%2F%2Fvimeo.com%2F435031083&poster=&time=true&progressBar=true&overlay=true&muteButton=true&fullscreenButton=true&style=light&quality=auto&playButton=true"
                frameborder="0"
              >
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--      Fin de cabecera-->
    <!--      Sección con scroll horizontal-->

    <div id="scroll-wrapper" class="container-fluid scroll-wrapper bg-light">
      <div id="scrollContainer" class="scroll-sections">
        <div
          id="fondo-puntos"
          style="overflow: hidden;width:50px;position:absolute;height: 100vh;z-index: 2;left:70vh;"
        >
          <img src="/img/caminos/camino-1.svg" alt="" style="position: absolute;top:40%" />
        </div>
        <div class="row scroll-section d-flex align-items-center">
          <div class="col-lg-5 offset-lg-1">
            <h2 class="mb-4 mt-5 mt-sm-0">Próxima parada, la escapada de tus sueños</h2>
            <p class="subtitular">
              En la Sierra Norte de Madrid te cambiamos el asfalto por el prado, los edificios por las montañas, el
              “para llevar” por un “para disfrutar” y los coches por una ruta en bici.
              <br />
              Hemos seleccionado para ti los mejores caminos, rutas y planes para que disfrutes del otro Madrid sin
              preocupaciones y en base a tus intereses... Elige y... ¡Disfruta!
            </p>
            <div class="d-none d-sm-block">
              <div class="row d-flex justify-content-center mt-4">
                <icon-aventureros />
                <icon-familias />
                <icon-foodies />
                <icon-naturaleza />
              </div>
              <div class="row d-flex justify-content-center mt-4">
                <icon-parejas />
                <icon-influencers />
                <icon-culturetas />
              </div>
            </div>
            <div class="d-sm-none">
              <div class="row d-flex justify-content-center mt-4">
                <icon-aventureros />
                <icon-familias />
                <icon-foodies />
                <icon-naturaleza />
                <icon-parejas />
                <icon-influencers />
                <icon-culturetas />
              </div>
            </div>
          </div>
          <div class="col-lg-5 offset-lg-1 mt-5 mt-lg-0">
            <div class="row">
              <div class="col-12 px-0 z-index-down">
                <img src="/img/042_Sierra_Norte_Low_5.jpg" alt="" class="img-fluid" />
              </div>
              <div class="col-lg-8 z-index-up">
                <h3 class="my-3">Planes para parejas</h3>
                <p>
                  ¿Te gusta hacer planes con tu pareja más allá del clásico “cine y cena”? ¡Esta ruta es para ti!
                </p>
                <nuxt-link to="/rutas/ruta-parejas" class="btn btn-primary mb-4">Allá vamos</nuxt-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row scroll-section d-flex align-items-center">
          <div class="col-lg-5 offset-lg-1 mt-5 mt-lg-0">
            <div class="row flex-lg-column-reverse">
              <div class="col-12 px-0 z-index-up">
                <img src="/img/rutas/ruta2/apicultura.jpg" alt="" class="img-fluid" />
              </div>
              <div class="col-lg-8 z-index-up">
                <h3 class="my-3">Planes para familias</h3>
                <p>
                  Contacto con animales, casas en los árboles... Disfruta de estos planes al aire libre con tus peques,
                  ¡o saca al niño que llevas dentro!
                </p>
                <nuxt-link to="/rutas/ruta-familias" class="btn btn-primary mb-4">Cuéntame más</nuxt-link>
              </div>
            </div>
          </div>
          <div class="col-lg-5 offset-lg-1 mt-5 mt-lg-0">
            <div class="row">
              <div class="col-12 px-0 z-index-down">
                <img src="/img/rutas/ruta3/hayedo.jpg" alt="" class="img-fluid" />
              </div>
              <div class="col-lg-8 z-index-up">
                <h3 class="my-3">Planes para amantes de la naturaleza</h3>
                <p>
                  El murmullo de una cascada lejana, un manto de hojas amarillas y tu mochila al hombro, ¿se te ocurre
                  un plan mejor?
                </p>
                <nuxt-link to="/rutas/ruta-naturaleza" class="btn btn-primary mb-4">¡Me apunto!</nuxt-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row scroll-section d-flex align-items-center">
          <div class="col-lg-5 offset-lg-1 mt-5 mt-lg-0">
            <div class="row flex-lg-column-reverse">
              <div class="col-12 px-0 z-index-up">
                <img src="/img/rutas/ruta4/puenting.jpg" alt="" class="img-fluid" />
              </div>
              <div class="col-lg-8 z-index-up">
                <h3 class="my-3">Planes para aventureros</h3>
                <p>
                  Imagina practicar vela, escalada y excursiones por la nieve en un mismo lugar. ¡Bienvenido a la Sierra
                  Norte de Madrid!
                </p>
                <nuxt-link to="/rutas/ruta-aventureros" class="btn btn-primary mb-4">Esto es lo mío</nuxt-link>
              </div>
            </div>
          </div>
          <div class="col-lg-5 offset-lg-1 mt-5 mt-lg-0">
            <div class="row">
              <div class="col-12 px-0 z-index-down">
                <img src="/img/rutas/ruta9/sin-barreras.jpg" alt="" class="img-fluid" />
              </div>
              <div class="col-lg-8 z-index-up">
                <h3 class="my-3">Planes accesibles</h3>
                <p>
                  En la Sierra Norte de Madrid...¡nada te detiene! Descubre nuestras actividades adaptadas a todos los
                  públicos.
                </p>
                <nuxt-link to="/rutas/ruta-accesible" class="btn btn-primary mb-4">¡Vamos!</nuxt-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row scroll-section d-flex align-items-center">
          <div class="col-lg-5 offset-lg-1 mt-5 mt-lg-0">
            <div class="row flex-lg-column-reverse">
              <div class="col-12 px-0 z-index-down">
                <img src="/img/rutas/ruta5/judiones.jpg" alt="" class="img-fluid" />
              </div>
              <div class="col-lg-8 z-index-up">
                <h3 class="my-3">Planes para foodies</h3>
                <p>
                  Si tu plan ideal lleva el nombre de “entrante, principal y postre”, esta ruta te dejará buen sabor de
                  boca.
                </p>
                <nuxt-link to="/rutas/ruta-foodies" class="btn btn-primary mb-4">¡Quiero ir!</nuxt-link>
              </div>
            </div>
          </div>
          <div class="col-lg-5 offset-lg-1 mt-5 mt-lg-0">
            <div class="row">
              <div class="col-12 px-0 z-index-up">
                <img src="/img/rutas/ruta6/bosque-finlandes.jpg" alt="" class="img-fluid" />
              </div>
              <div class="col-lg-8 z-index-up">
                <h3 class="my-3">Planes para influencers</h3>
                <p>
                  ¿El filtro “Valencia” es tu mejor aliado? Nuestros parajes naturales te enamorarán hasta que se te
                  olvide subir stories para contarlo.
                </p>
                <nuxt-link to="/rutas/ruta-influencers" class="btn btn-primary mb-4">¡No puedo creerlo!</nuxt-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row scroll-section d-flex align-items-center">
          <div class="col-lg-5 offset-lg-1 mt-5 mt-lg-0">
            <div class="row flex-lg-column-reverse">
              <div class="col-12 px-0 z-index-down">
                <img src="/img/rutas/ruta1/museo-aire-libre-valle.jpg" alt="" class="img-fluid" />
              </div>
              <div class="col-lg-8 z-index-up">
                <h3 class="my-3">Planes para culturetas</h3>
                <p>
                  ¡Hay vida cultural en Madrid más allá de su casco histórico! Descubre lugares sorprendentes, ¡que
                  están a un paso!
                </p>
                <nuxt-link to="/rutas/ruta-culturetas" class="btn btn-primary mb-4">Saber más</nuxt-link>
              </div>
            </div>
          </div>
          <div class="col-lg-5 offset-lg-1 mt-5 mt-lg-0">
            <div class="row">
              <div class="col-12 px-0 z-index-up">
                <img src="/img/rutas/ruta8/la-hiruela.jpg" alt="" class="img-fluid" />
              </div>
              <div class="col-lg-8 z-index-up">
                <h3 class="my-3">Planes para todos</h3>
                <p>
                  Pueblos con encanto y naturaleza que enamoran: ¡una selección de los lugares imprescindibles de
                  nuestra Sierra Norte de Madrid!
                </p>
                <nuxt-link to="/rutas/ruta-todos" class="btn btn-primary mb-4">No me lo pierdo</nuxt-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    Fin sección scroll horizontal-->
  </div>
</template>

<script>
import { TimelineMax } from 'gsap'

export default {
  head: {
    titleTemplate: 'El otro Madrid - Esto (también) es Madrid',
    meta: [
      { name: 'description', content: 'Descubre todo lo que puede ofrecerte la Sierra Norte de Madrid' },
      { property: 'og:image', content: '//www.elotromadrid.com/img/101_Sierra_Norte_Low_4.jpg' },
      { property: 'og:title', content: 'El otro Madrid' },
      { property: 'og:description', content: 'Descubre todo lo que puede ofrecerte la Sierra Norte de Madrid' },
      { property: 'og:url', content: '//www.elotromadrid.com' },
      { name: 'twitter:image', content: '//www.elotromadrid.com/img/101_Sierra_Norte_Low_4.jpg' },
      { name: 'twitter:title', content: 'El otro Madrid' },
      { name: 'twitter:description', content: 'Descubre todo lo que puede ofrecerte la Sierra Norte de Madrid' },
      { name: 'twitter:card', content: 'summary_large_image' }
    ]
  },
  components: {
    IconCulturetas: () => import('../components/iconos/IconCulturetas'),
    IconFamilias: () => import('../components/iconos/IconFamilias'),
    IconFoodies: () => import('../components/iconos/IconFoodies'),
    IconInfluencers: () => import('../components/iconos/IconInfluencers'),
    IconParejas: () => import('../components/iconos/IconParejas'),
    IconNaturaleza: () => import('../components/iconos/IconNaturaleza'),
    IconAventureros: () => import('../components/iconos/IconAventureros')
  },
  mounted() {
    // const mobileRE = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i

    // if (!mobileRE.test(this.window.navigator.userAgent)) {
    if (window.innerWidth > 1000) {
      const horizontalSlide = new TimelineMax().to('#scrollContainer', 1, { x: '-78%' })
      const scene = this.$scrollmagic
        .scene({
          triggerElement: '#scroll-wrapper',
          triggerHook: 'onLeave',
          duration: '400%'
        })
        .setPin('#scroll-wrapper')
        .setTween(horizontalSlide)
      const scene2 = this.$scrollmagic
        .scene({
          triggerElement: '#scroll-wrapper',
          triggerHook: 'onLeave',
          duration: '450%'
        })
        // .setPin('#fondo-puntos')
        .setTween('#fondo-puntos', {
          width: '92%'
        })
      this.$scrollmagic.addScene(scene)
      this.$scrollmagic.addScene(scene2)
    }
  }
}
</script>
